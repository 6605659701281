<!-- Control Multi-Finder Fechas -->

<template>
  <div v-if="schema">
    <v-menu offset-y>
      <template v-slot:activator="{on}">
        <div class="conflex">
          <v-icon v-if="items.length" x-samll v-on="on">{{ 'mdi-chevron-down' }}</v-icon>
          <vlinput
            type="date" 
            :label="elem_menuSeleccionado.label"
            v-model="schema.ctrls[elem_menuSeleccionado.ctrl].value"> </vlinput>
        </div>
      </template>

      <!-- Lista de opciones de Busqueda -->
      <v-list>
        <div style="text-align:center"> Seleccione filtro </div>
        <v-divider></v-divider>
        
        <v-list-item v-for="(item, index) in items" :key="index">
          <v-list-item-title @click="onclick_selectBusqueda(item)">
            {{ item.label }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>



<script>
export default {
  props: {
    items: { type: [Object, Array], required: true },
    schema: { type: [Object, Array], required: true },
  },

  data() {
    return {
      // variables de configuración
      Entorno: this.$cfg.ctrls.multifilterFechas,

      // variables del componente
      elem_menuSeleccionado: {},
      modal: false,
      idx: null
    };
  },

  // establezco el ctrl de busqueda al primer elemento del array
  // de ctrls recibido
  created() {
    if (!this.items) return;
    this.setBusqueda(this.items[0]);
  },

  methods: {

    // limpio datos de todo los items
    limpia() {   
      for (let index in this.items) {        
        this.schema.ctrls[this.items[index].ctrl].value= "";
      }
    },


    // selecciono un tipo de busqueda de la lista asociada al textfield
    // , inicializo a '' sus valores
    onclick_selectBusqueda(item) {      
      this.limpia();
      this.setBusqueda(item);      
    },

    // emito evento buscar para proceder a la busqueda
    onclick_buscar() {
      if (!this.elem_menuSeleccionado) return;
      this.$emit("onSearch", this.elem_menuSeleccionado.buscar);
    },

    // configuro la busqueda con el item recibido.
    // actualizo el label del textfield y guardo el item seleccionado
    setBusqueda(item) {
      console.log(item);
      this.elem_menuSeleccionado = item;
      this.$emit('onEvent', { accion:'change', item:item })
    },

  },


};

</script>

<style scope>
.multi_filterFechas .boton_busqueda {
  margin: 4px 0px 0px 10px;
}
</style>
